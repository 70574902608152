import React, { useState, useEffect, useRef } from 'react';
import AdminTopbar from '../../admin/layouts/Topbar';
import '../../admin/styles/pages/dashboard.css';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Typography, TextField, InputAdornment,
    MenuItem, Button, InputLabel, FormControl, IconButton, Radio, RadioGroup, FormControlLabel, Menu, Select, FormLabel, DialogTitle, DialogContent,
    Dialog, DialogActions, Grid, Tabs, Tab,
    Tooltip
} from '@mui/material';
import instance from '../../utils/api';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import VisibilityIcon from '@mui/icons-material/Visibility';
import download_tickets from "../../superadmin/assets/icons/download_tickets.svg";
import excel from '../../superadmin/assets/icons/excel.svg'
import { styled } from "@mui/material/styles";
import view from '../../superadmin/assets/icons/table/view.svg'
import upload_image_1 from '../../superadmin/assets/icons/upload.png'
import upload_image_2 from '../../superadmin/assets/icons/upload.png';
import edit from "../../superadmin/assets/icons/table/edit.svg";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';




const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const Tickets = () => {
    const role = localStorage.getItem('role'); //role get from localstorage
    const name = localStorage.getItem('name'); //role and name get from localstorage
    const [ticketData, setTicketData] = useState(null); // Combined state for both public and private tickets
    const [isPrivateTickets, setIsPrivateTickets] = useState(false); // Flag to indicate if the ticket is private or public
    const [open2, setOpen2] = useState(false);   //popup
    const [currentTabIndex, setCurrentTabIndex] = useState(0);   //usestate for tabs
    const [employees, setemployees] = useState([]);  //usestate for employees
    const [anchorEl, setAnchorEl] = React.useState(null); //filter usestate
    const open = Boolean(anchorEl);//nenu
    const [tickets, settickets] = useState([]); //usestate for Tickets
    const [pagination, setpagination] = useState([]); //usesate for pagination
    const [loading, setLoading] = useState(true); // usesate for loading
    const [page, setPage] = useState(1);   //page setup 
    const [pagepublic, setPagepublic] = useState(1);    //page usestate
    const rowsPerPage = 10; // Fixed rows per page
    const [sortOrder, setSortOrder] = useState('asc');//usesate for array sorting
    const [sortBy, setSortBy] = useState('');//usesate for sort
    const [searchTerm, setSearchTerm] = useState(''); // usesate for Search 
    const [employeeId, setemployeeId] = useState("");  //employeeid 
    const [status, setstatus] = useState(""); // status
    const [edittickets, setedittickets] = useState(null); //usestate for update
    const [openDialog, setOpenDialog] = useState(false); //manager popup usestate
    const startIndex = (page - 1) * rowsPerPage;   // Calculate the start and end index for pagination
    const startIndexpublic = (pagepublic - 1) * rowsPerPage;  // Calculate the start and end index for pagination
    const [publicpagination, setpublicpagination] = useState("")//public tickects usesate
    const [isPublicTicket, setIsPublicTicket] = useState(false);
    const [commonstatus, setcommonStatus] = useState('');
    const [description, setDescription] = useState('');
    const [file, setfile] = useState('');
    const [statuspublic, setstatuspublic] = useState(""); //usestate for update
    const [input, setinput] = useState(""); //usestate for update  
    const [publicticket, setpublicticket] = useState([]); //usestate for getPublicTickets
    const viewstaffticketstooltip = "View Staff Tickets";
    const viewpublicticketstooltip = "View Public Tickets";
    const updateticketstatus = "Change Ticket Status";
    const filtertooltip = "Filter Menu"; // Define the tooltip text
    const clicktodownload = "Click to download the image"; // Define the tooltip text

    //filter menu function open
    const handleClick1 = (event) => {
        setAnchorEl(event.currentTarget);
    };

    //filter menu function close
    const handleClose1 = () => {
        setAnchorEl(null);
    };
    //view tickets function
    const handleClose2 = () => {
        setOpen2(false);
    };
    //tabs function
    const handleTabChange = (e, tabIndex) => {
        setCurrentTabIndex(tabIndex);
    };

    // Utility function to add leading zeros
    const formatNumber = (num, size) => {
        let s = String(num);
        while (s.length < size) s = "0" + s;
        return s;
    };

    //sorting function
    const handleSort = (primaryField, secondaryField = null) => {
        const isAsc = sortBy === primaryField && sortOrder === 'asc';
        setSortOrder(isAsc ? 'desc' : 'asc');
        setSortBy(primaryField);

        const sortedData = [...tickets].sort((a, b) => {
            const valA1 = `${a[primaryField][secondaryField] || a[primaryField]}`;
            const valB1 = `${b[primaryField][secondaryField] || b[primaryField]}`;

            if (valA1 < valB1) return isAsc ? -1 : 1;
            if (valA1 > valB1) return isAsc ? 1 : -1;

            // If primary field is equal, compare by secondary field if available
            if (secondaryField) {
                const valA2 = `${a[primaryField][secondaryField]}`;
                const valB2 = `${b[primaryField][secondaryField]}`;

                if (valA2 < valB2) return isAsc ? -1 : 1;
                if (valA2 > valB2) return isAsc ? 1 : -1;
            }

            return 0;
        });

        settickets(sortedData);
    };


    //sorting function publictickets
    const handleSortpublic = (primaryField, secondaryField = null) => {
        const isAsc = sortBy === primaryField && sortOrder === 'asc';
        setSortOrder(isAsc ? 'desc' : 'asc');
        setSortBy(primaryField);

        const sortedData = [...publicticket].sort((a, b) => {
            const valA1 = `${a[primaryField][secondaryField] || a[primaryField]}`;
            const valB1 = `${b[primaryField][secondaryField] || b[primaryField]}`;

            if (valA1 < valB1) return isAsc ? -1 : 1;
            if (valA1 > valB1) return isAsc ? 1 : -1;

            // If primary field is equal, compare by secondary field if available
            if (secondaryField) {
                const valA2 = `${a[primaryField][secondaryField]}`;
                const valB2 = `${b[primaryField][secondaryField]}`;

                if (valA2 < valB2) return isAsc ? -1 : 1;
                if (valA2 > valB2) return isAsc ? 1 : -1;
            }

            return 0;
        });

        setpublicticket(sortedData);
    };

    //newpage function
    const handleChangePage = (event, newPage) => {
        setPage(newPage + 1); // Adjust to be 1-based
    };

    //public page 
    const handleChangePagepublic = (event, newPage) => {
        setPagepublic(newPage + 1); // Adjust to be 1-based
    };
    // upadate manager popup open saff tickects popup
    const handleopenDialog = (edittickets, updatestatus) => {
        setDescription("");
        setcommonStatus(edittickets?.status);
        setIsPublicTicket(updatestatus)
        setedittickets(edittickets);
        setOpenDialog(true);
    };

    // upadate manager popup close saff tickects popup
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setedittickets(null);
    };


    // Function to handle selecting private tickets  
    const handleTicketSelection = (ticket, isPrivate) => {
        setIsPrivateTickets(isPrivate);
        setTicketData(ticket);
        setOpen2(true);
    };

    //fetch getAllEmployees function
    const getAllEmployees = async () => {
        try {
            const response = await instance.post("Manager/getEmployees");
            setemployees(response.data.employees);
        } catch (error) {
            console.error('Error fetching employees:', error.message);
        }
    };

    useEffect(() => {
        getAllEmployees();
    }, []);

    //get Tickets api call
    const fetchtickects = async (category) => {
        try {
            const response = await instance.post(`/Employee/getAssignTickets?category=${category}`);
            console.log(response);
            
            settickets(response.data.tickets);
            setpagination(response.data.pagination)
        } catch (error) {
            console.error('Error fetching Tickets:', error.message);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if(currentTabIndex === 0){
            fetchtickects('Employee');

        }else{
            fetchtickects('Public');
        }
    }, [currentTabIndex]);


    //resettickets api call
    const fetchtickectsReset = async () => {
        try {
            const response = await instance.post(`/Employee/getAssignTickets?category=${'Employee'}`);
            settickets(response.data.tickets);
            setpagination(response.data.pagination);
            handleClose1()
        } catch (error) {
            console.error('Error fetching Tickets:', error.message);
        } finally {
            setLoading(false);
        }
    };


    //onsubmit function
    const onsubmit = () => {
        if (employeeId || status) {
            setPage(1)
            // If both have values, call the fetchtickets function
            fetchtickects();
            handleClose1()
            setemployeeId("");
            setstatus("");
        } else {
            // If either employeeId or status is missing, show an error message
            toast.error("Please enter employee Id and status");
        }
    }

    // Call your update API or function here
    const ticketupdatecommissioner = async () => {
        try {
            // Validation
            if (commonstatus === "Completed") {
                if (!commonstatus || !description || !file) {
                    toast.error('Please enter both status description and file.');
                    return;
                }
            } else {
                if (!commonstatus || !description) {
                    toast.error('Please enter both status and description');
                    return;
                }
            }
            const formData = new FormData();
            formData.append('status', commonstatus);
            formData.append('commissionerDescription', description);
            formData.append('Completedby', name);
            formData.append('CompletedbyRole', role);
            formData.append('file', file);

            const response = await instance.post(`Manager/updateTicket/${edittickets._id}`, formData);
            if (response.data.success === true) {
                toast.success(response.data.message);
                handleCloseDialog()
                fetchtickects()
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error.response.message);
            console.error(error.response);
        }
    }

    //fetch getPublicTickets
    const getPublicTickets = async () => {
        try {
            const response = await instance.post(`Manager/getPublicTickets?page=${pagepublic}`, { status: statuspublic, input });
            setpublicticket(response.data.public_Tickets);
            setpublicpagination(response.data.pagination)
        } catch (error) {
            console.error('Error fetching employees:', error.message);
        }
    };

    useEffect(() => {
        getPublicTickets();
    }, [pagepublic]);

    //resettickets
    const fetcpublichtickectsReset = async () => {
        try {
            const response = await instance.post(`Manager/getPublicTickets?page=${1}`);
            setpublicticket(response.data.public_Tickets);
            setpublicpagination(response.data.pagination)
            handleClose1()
        } catch (error) {
            console.error('Error fetching Tickets:', error.message);
        }
    };

    //onsubmit function
    const onsubmitpublic = () => {
        if (input || statuspublic) {
            setPagepublic(1)
            // If both have values, call the fetchtickets function
            getPublicTickets();
            handleClose1()
            setinput("")
            setstatuspublic("")
        } else {
            // If either employeeId or status is missing, show an error message
            toast.error("Please enter department Id and status");
        }
    }

    //  Call your update API or function here
    const updatePublicTickets = async () => {
        try {
            // Validation
            if (commonstatus === "Completed") {
                if (!commonstatus || !description || !file) {
                    toast.error('Please enter both status description and file.');
                    return;
                }
            } else {
                if (!commonstatus || !description) {
                    toast.error('Please enter both status and description');
                    return;
                }
            }
            const formData = new FormData();
            formData.append('status', commonstatus);
            formData.append('commissionerDescription', description);
            formData.append('Completedby', name);
            formData.append('CompletedbyRole', role);
            formData.append('file', file);

            const response = await instance.post(`Manager/updatePublicTickets/${edittickets._id}`, formData);
            if (response.data.success === true) {
                toast.success(response.data.message);
                handleCloseDialog()
                getPublicTickets()

            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error.response.message);
            console.error(error.message);
        }
    }



    //export data integration
    const handleDownloadExportData = async () => {
        try {
            const response = await instance.get("Manager/getExcelDownload", {
                responseType: 'blob', // Treat the response as a Blob
            });
            downloadExportData(response.data);
        } catch (error) {
            console.error("Error fetching export data:", error.message);
        }
    };

    const downloadExportData = (data) => {
        if (data) {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Staff Tickets.xlsx"); // Set the filename for the downloaded file
            document.body.appendChild(link);
            link.click();
        }
    };



    //export data integration for public
    const handleDownloadExportDatapublic = async () => {
        try {
            const response = await instance.get("Manager/getPublicExcelDownload", {
                responseType: 'blob', // Treat the response as a Blob
            });
            downloadExportDatapublic(response.data);
        } catch (error) {
            console.error("Error fetching export data:", error.message);
        }
    };

    const downloadExportDatapublic = (data) => {
        if (data) {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Public Tickets.xlsx"); // Set the filename for the downloaded file
            document.body.appendChild(link);
            link.click();
        }
    };

    //download Image
    const source = `https://nudabackend.vibhohcm.com/public/${ticketData?.imageUrl}`;
    const downloadsource = `https://nudabackend.vibhohcm.com/public/${ticketData?.completed_imageUrl}`;
    const linkRef = useRef()
    async function downloadImage(e) {
        e.preventDefault();

        try {
            const src = linkRef.current.href
            const response = await fetch(src);
            if (!response.ok) {
                throw new Error(`Failed to fetch the image: ${response.statusText}`);
            }
            const imageBlob = await response.blob();
            const imageURL = URL.createObjectURL(imageBlob);

            linkRef.current.href = imageURL;
            linkRef.current.download = 'ProblemImage';
            linkRef.current.click();

            URL.revokeObjectURL(imageURL);
        } catch (error) {
            console.error("Error downloading the image:", error.message);
        }
    }
    //download completd image
    const sourcefile = ticketData?.CompletedImage;
    const linkReffile = useRef()
    async function downloadcompletedImage(e) {
        e.preventDefault();

        try {
            const src = linkReffile.current.href
            const response = await fetch(src);
            if (!response.ok) {
                throw new Error(`Failed to fetch the image: ${response.statusText}`);
            }
            const imageBlob = await response.blob();
            const imageURL = URL.createObjectURL(imageBlob);

            linkReffile.current.href = imageURL;
            linkReffile.current.download = 'CompletedImage';
            linkReffile.current.click();

            URL.revokeObjectURL(imageURL);
        } catch (error) {
            console.error("Error downloading the image:", error.message);
        }
    }

    return (
        <React.Fragment>
            <AdminTopbar />
            <section className='popupsection'>
                <div className='add_managers add_tickets table_add_tickets'>
                    {/* view tickets Dialog staff*/}
                    <React.Fragment>
                        <BootstrapDialog
                            onClose={handleClose2}
                            aria-labelledby="customized-dialog-title"
                            open={open2}
                            className="add_departments add_tickets"
                        >
                            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title"></DialogTitle>
                            <IconButton
                                aria-label="close"
                                onClick={handleClose2}
                                sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <DialogContent dividers>
                                <div className='add_managers add_tickets table_add_tickets'>
                                    <React.Fragment>
                                        <BootstrapDialog
                                            onClose={handleClose2}
                                            aria-labelledby="customized-dialog-title"
                                            open={open2}
                                            className="add_departments add_tickets"
                                        >
                                            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                                                {isPrivateTickets ? "Staff Ticket Details" : "Public Ticket Details"}
                                            </DialogTitle>
                                            <IconButton
                                                aria-label="close"
                                                onClick={handleClose2}
                                                sx={{
                                                    position: "absolute",
                                                    right: 8,
                                                    top: 8,
                                                    color: (theme) => theme.palette.grey[500],
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                            <DialogContent dividers>
                                                <div className='add_tickets_details'>
                                                    <div className='add_tickets_content'>
                                                        <div className='add_tickets_content_list'>
                                                            <h4>Ticket ID:</h4>
                                                            <p>{ticketData?.ticket_no ? ticketData?.ticket_no : "-----"}</p>
                                                        </div>
                                                        <div className='add_tickets_content_list'>
                                                            <h4>Date Created:</h4>
                                                            <p>{new Date(ticketData?.createdAt).toLocaleString()}</p>
                                                        </div>
                                                        <div className='add_tickets_content_list'>
                                                            <h4>{isPrivateTickets ? "Staff Mobile Number:" : "User Mobile Number:"}</h4>
                                                            {isPrivateTickets ?
                                                                (<p>{ticketData?.employeeId?.countrycode} {ticketData?.employeeId?.mobileNo}</p>) :
                                                                (<p>{ticketData?.authourId?.countrycode} {ticketData?.authourId?.mobileNo}</p>)}
                                                        </div>
                                                    </div>
                                                    <div className='add_tickets_content add_tickets_content_1'>
                                                        <div className='add_tickets_content_list'>
                                                            <h4>{isPrivateTickets ? "Staff FullName:" : "User FullName"}</h4>
                                                            {isPrivateTickets ?
                                                                <p>{ticketData?.employeeId?.firstName} {ticketData?.employeeId?.lastName}</p>
                                                                :
                                                                <p>{ticketData?.authourId?.username}</p>}
                                                        </div>
                                                        <div className='add_tickets_content_list'>
                                                            <h4>Department Name:</h4>
                                                            <p>{ticketData?.issueDepartmentId?.name}</p>
                                                        </div>
                                                        <div className='add_tickets_content_list'>
                                                            <h4>Ticket Status:</h4>
                                                            <p style={{
                                                                background: ticketData?.status === 'Created' ? '#FFF7F0' : ticketData?.status === 'Completed' ? '#E5FCF0' : ticketData?.status === 'Inprogress' ? '#EDF5FF' : ticketData?.status === 'Rejected' ? 'rgb(255, 232, 232)' : 'inherit',
                                                                color: ticketData?.status === 'Created' ? '#F2994A' : ticketData?.status === 'Completed' ? '#00A651' : ticketData?.status === 'Inprogress' ? '#2F80ED' : ticketData?.status === 'Rejected' ? '#EB5757' : 'inherit',
                                                            }}>{ticketData?.status}</p>
                                                        </div>
                                                    </div>

                                                    {/* <div className='add_tickets_content_list'> */}
                                                    {ticketData?.ticket_Status_Update.length > 0 ? (
                                                        <TableContainer component={Paper} className='ticket_status_table_main'>
                                                            <Table className='ticket_status_table' aria-label="status updates table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>From Status</TableCell>
                                                                        <TableCell>To Status</TableCell>
                                                                        <TableCell>Status Updated Name</TableCell>
                                                                        <TableCell>Status Updated Role</TableCell>
                                                                        <TableCell>Status Updated Date</TableCell>
                                                                        <TableCell>Status Description</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {ticketData.ticket_Status_Update.map((update) => (
                                                                        <TableRow key={update._id}>
                                                                            <TableCell>{update.Status_Updated_from}</TableCell>
                                                                            <TableCell>{update.Status_Updated_to}</TableCell>
                                                                            <TableCell>{update.Status_Updated_name}</TableCell>
                                                                            <TableCell>{update.Status_Updated_role}</TableCell>
                                                                            <TableCell>{update.Status_Updated_date}</TableCell>
                                                                            <TableCell> {update.Status_Updated_description}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    ) : (
                                                        null)}

                                                    {/* </div> */}
                                                    <div className='description'>
                                                        <h3>{isPrivateTickets ? "Staff Description" : "User Description"}</h3>
                                                        <p>{ticketData?.description}</p>
                                                    </div>
                                                    {ticketData?.latitude && ticketData?.longitude ? (
                                                        <div className="description">
                                                            <h3>Ticket Location</h3>
                                                            <a href={`https://www.google.com/maps?q=${ticketData.latitude},${ticketData.longitude}`} target="_blank" rel="noopener noreferrer">
                                                                https://www.google.com/maps?q={ticketData.latitude},{ticketData.longitude}
                                                            </a>
                                                        </div>
                                                    ) : null}
                                                    <div style={{ display: "flex", justifyContent: "space-between", width: "360px" }}>
                                                        <div className='problem_images'>
                                                            <h3>Problem Images</h3>
                                                            {ticketData?.imageUrl ?
                                                                (
                                                                    <div className='problem_images_layout'>
                                                                        <div className='problem_images_layout_content'>
                                                                            <a ref={linkRef} href={source} download="download" >
                                                                                <Tooltip title={clicktodownload}><img onClick={downloadImage} src={source} /></Tooltip>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                ) :
                                                                (<p>No photo has been uploaded</p>)
                                                            }
                                                        </div>
                                                        <div className='problem_images'>
                                                            {ticketData?.completed_imageUrl ?
                                                                (
                                                                    <>
                                                                        <h3>Completed Image</h3>
                                                                        <div className='problem_images_layout'>
                                                                            <div className='problem_images_layout_content'>
                                                                                <a ref={linkReffile} href={downloadsource} download="download" >
                                                                                    <Tooltip title={clicktodownload}><img onClick={downloadImage} src={downloadsource} /></Tooltip>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                                : null}
                                                        </div>
                                                        <div className='problem_images'>
                                                            {ticketData?.CompletedImage ?
                                                                (
                                                                    <>
                                                                        <h3>Completed Image</h3>
                                                                        <div className='problem_images_layout'>
                                                                            <div className='problem_images_layout_content'>
                                                                                <a ref={linkReffile} href={sourcefile} download="download" >
                                                                                    <Tooltip title={clicktodownload}><img onClick={downloadcompletedImage} src={sourcefile} /></Tooltip>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                                : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </DialogContent>
                                        </BootstrapDialog>
                                    </React.Fragment>
                                </div>
                            </DialogContent>
                        </BootstrapDialog>
                    </React.Fragment>

                    {/* Change Confirmation Dialog staff*/}
                    <section>
                        <React.Fragment>
                            <BootstrapDialog
                                onClose={handleCloseDialog}
                                aria-labelledby="customized-dialog-title"
                                open={openDialog}
                                className="add_departments"
                            >
                                <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
                                    {isPublicTicket ? "Updated Ticket Status For staff" : "Updated Ticket Status For public"}
                                </DialogTitle>
                                <IconButton
                                    aria-label="close"
                                    onClick={handleCloseDialog}
                                    sx={{
                                        position: "absolute",
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <DialogContent dividers>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <div className="filter_dropdown">
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">status</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="status"
                                                        value={commonstatus}
                                                        onChange={(e) => setcommonStatus(e.target.value)}
                                                    >
                                                        {commonstatus !== "Inprogress" && <MenuItem value="Created">Created</MenuItem>}
                                                        <MenuItem value="Rejected">Rejected</MenuItem>
                                                        <MenuItem value="Inprogress">Inprogress</MenuItem>
                                                        <MenuItem value="Completed">Completed</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={12}>
                                            <TextField
                                                id="outlined-basic"
                                                label="Description"
                                                variant="outlined"
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={12}>
                                            {commonstatus === "Completed" && (
                                                <div className="departments_forms">
                                                    <TextField
                                                        id="outlined-basic"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(e) => setfile(e.target.files[0])}
                                                    />
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions className="footer_button">
                                    <Button className="cancel_button" onClick={handleCloseDialog}>Cancel</Button>
                                    <Button className="add_button" onClick={isPublicTicket ? ticketupdatecommissioner : updatePublicTickets}>{isPublicTicket ? "Update" : "Update"}</Button>
                                </DialogActions>
                            </BootstrapDialog>
                        </React.Fragment>
                    </section>
                </div>
            </section>

            {/* tabs */}
            <section className='tabs-section'>
                <React.Fragment>
                    <Tabs value={currentTabIndex} onChange={handleTabChange}>
                        <Tab label='Staff Tickets' />
                        <Tab label='Public Tickets' />
                    </Tabs>
                </React.Fragment>

                {/* first tab */}
                {currentTabIndex === 0 && (
                    <React.Fragment>
                        {loading ? (
                            <div className="loading" >
                                <CircularProgress />
                            </div>
                        ) : (
                            <section className='employees-1'>
                                <div className='employees-text'>
                                    <Typography variant='h5'>
                                        Tickets Details
                                    </Typography>
                                    <Button onClick={handleDownloadExportData}><img src={excel} alt='excel' style={{ filter: "brightness(0) invert(1)", width: "20px" }} />Download Report</Button>
                                </div>
                                <div className='employees-main'>
                                    <div className='employees-content'>
                                        <div className='search-options'>
                                            {/* <TextField
                        placeholder='Search'
                        variant="outlined"
                        fullWidth
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <SearchIcon />
                            </InputAdornment>
                          )
                        }}
                      /> */}
                                        </div>
                                        <div className='pagination-options'>
                                            <div className='pagination-animation'></div>
                                            <TablePagination
                                                component="div"
                                                count={pagination?.totaltickets}
                                                page={page - 1} // Adjust to be 0-based for MUI TablePagination
                                                onPageChange={handleChangePage}
                                                rowsPerPage={pagination?.limit}
                                                rowsPerPageOptions={[]}
                                                labelDisplayedRows={({ from, to, count }) => (
                                                    <React.Fragment>
                                                        {formatNumber(from, 1)} to <span>{formatNumber(to, 2)}</span>
                                                        <span>of{' '} {count !== -1 ? count : `more than ${to}`}</span>
                                                    </React.Fragment>
                                                )}
                                            />
                                            <div className='filter'>
                                                <IconButton
                                                    id="basic-button"
                                                    aria-controls={open ? 'basic-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={handleClick1}
                                                >
                                                    <Tooltip title={filtertooltip}><FilterAltIcon /></Tooltip>
                                                </IconButton>
                                                <Menu
                                                    id="basic-menu"
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose1}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'basic-button',
                                                    }}
                                                    className='filter-options'
                                                >
                                                    <div className='filter-menu'>
                                                        <Typography variant='h6'>Filter Staff Tickets</Typography>
                                                        <CloseIcon onClick={handleClose1} />
                                                    </div>
                                                    <div className='filter-status'>
                                                        <div className="filter_dropdown filter-tickets">
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Staff</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    label="Staff"
                                                                    value={employeeId}
                                                                    onChange={(e) => setemployeeId(e.target.value)}
                                                                >
                                                                    <MenuItem value="">Select Staff</MenuItem>
                                                                    {employees.map((employee) => (
                                                                        <MenuItem key={employee?._id} value={employee?._id}>{employee?.firstName + employee?.lastName}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <div className='filter-active'>
                                                            <FormControl>
                                                                <FormLabel id="demo-radio-buttons-group-label">Status</FormLabel>
                                                                <RadioGroup
                                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                                    defaultValue="female"
                                                                    name="radio-buttons-group"
                                                                    value={status}
                                                                    onChange={(e) => setstatus(e.target.value)}
                                                                >
                                                                    <div>
                                                                        <FormControlLabel value="Inprogress" control={<Radio />} label="Inprogress" className="InProgress" />
                                                                        <FormControlLabel value="Created" control={<Radio />} label="Created" className='Pending' />
                                                                    </div>
                                                                    <div>
                                                                        <FormControlLabel value="Completed" control={<Radio />} label="Completed" className='active1' />
                                                                        <FormControlLabel value="Rejected" control={<Radio />} label="Rejected" className='inactive1' />
                                                                    </div>
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    <div className='apply_fliters filter-btn'>
                                                        <Button className='reset-filter' onClick={() => {
                                                            setemployeeId("");
                                                            setstatus("");
                                                            setPage(1);
                                                            fetchtickectsReset();
                                                        }}>Reset Fliter</Button>
                                                        <Button onClick={onsubmit}>Apply Fliter</Button>
                                                    </div>
                                                </Menu>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='employee-table'>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            <div className='employee-index'>
                                                                S.NO
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className='employee-name' onClick={() => handleSort('ticket_no')}>
                                                                Ticket No
                                                                <NorthIcon />
                                                                <SouthIcon />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className='employee-name' onClick={() => handleSort('createdAt')}>
                                                                Created Date
                                                                <NorthIcon />
                                                                <SouthIcon />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className='employee-name' onClick={() => handleSort('employeeId', 'firstName' && 'lastName')}>
                                                                Staff Name
                                                                <NorthIcon />
                                                                <SouthIcon />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className='employee-name' onClick={() => handleSort('mobileNo')}>
                                                                Staff Mobile Number
                                                                <NorthIcon />
                                                                <SouthIcon />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className='employee-name' onClick={() => handleSort('status')}>
                                                                Ticket Status
                                                                <NorthIcon />
                                                                <SouthIcon />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className='employee-index'>
                                                            Action
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {tickets.length > 0 ? (
                                                        tickets.map((ticket, index) => (
                                                            <TableRow key={ticket._id}>
                                                                <TableCell align="left"> {startIndex + index + 1}</TableCell>
                                                                <TableCell align="left"> {ticket?.ticket_no ? ticket?.ticket_no : "-----"}</TableCell>
                                                                <TableCell align="left">{new Date(ticket.createdAt).toLocaleString()}</TableCell>
                                                                <TableCell align="left">{ticket?.employeeId?.firstName} {ticket?.employeeId?.lastName}</TableCell>
                                                                <TableCell align="left">{ticket?.employeeId?.countrycode} {ticket?.employeeId?.mobileNo}</TableCell>
                                                                <TableCell align="left">
                                                                    <span style={{
                                                                        background: ticket.status === 'Created' ? '#FFF7F0' : ticket.status === 'Completed' ? '#E5FCF0' : ticket.status === 'Inprogress' ? '#EDF5FF' : ticket.status === 'Rejected' ? 'rgb(255, 232, 232)' : 'inherit',
                                                                        color: ticket.status === 'Created' ? '#F2994A' : ticket.status === 'Completed' ? '#00A651' : ticket.status === 'Inprogress' ? '#2F80ED' : ticket.status === 'Rejected' ? '#EB5757' : 'inherit',
                                                                    }}>
                                                                        {ticket.status}
                                                                    </span>
                                                                </TableCell>
                                                                <TableCell align="left" className='tickects-lasttablecell'>
                                                                    <Tooltip title={viewstaffticketstooltip}><div className='view-btn' onClick={() => handleTicketSelection(ticket, true)}><VisibilityIcon /></div></Tooltip>
                                                                    {ticket?.status !== "Completed" && (
                                                                        <div className='edit-btn' onClick={() => { handleopenDialog(ticket, true) }}>
                                                                            <Tooltip title={updateticketstatus}><img src={edit} alt="" /></Tooltip>
                                                                        </div>
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell colSpan={6} align="center">
                                                                <Typography>No Data Found</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                            </section>
                        )}
                    </React.Fragment>
                )}

                {/* second tab */}
                {currentTabIndex === 1 && (
                    <React.Fragment>
                        {loading ? (
                            <div className="loading" >
                                <CircularProgress />
                            </div>
                        ) : (
                            <section className='employees-1'>
                                <div className='employees-text'>
                                    <Typography variant='h5'>
                                        Tickets Details
                                    </Typography>
                                    <Button onClick={handleDownloadExportDatapublic}><img src={excel} alt='excel' style={{ filter: "brightness(0) invert(1)", width: "20px" }} />Download Report</Button>
                                </div>
                                <div className='employees-main'>
                                    <div className='employees-content'>
                                        <div className='search-options'>
                                            {/* <TextField
                        placeholder='Search'
                        variant="outlined"
                        fullWidth
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <SearchIcon />
                            </InputAdornment>
                          )
                        }}
                      /> */}
                                        </div>
                                        <div className='pagination-options'>
                                            <div className='pagination-animation'></div>
                                            <TablePagination
                                                component="div"
                                                count={publicpagination?.totaltickets}
                                                page={pagepublic - 1} // Adjust to be 0-based for MUI TablePagination
                                                onPageChange={handleChangePagepublic}
                                                rowsPerPage={publicpagination?.limit}
                                                rowsPerPageOptions={[]}
                                                labelDisplayedRows={({ from, to, count }) => (
                                                    <React.Fragment>
                                                        {formatNumber(from, 1)} to <span>{formatNumber(to, 2)}</span>
                                                        <span>of{' '} {count !== -1 ? count : `more than ${to}`}</span>
                                                    </React.Fragment>
                                                )}
                                            />
                                            <div className='filter'>
                                                <IconButton
                                                    id="basic-button"
                                                    aria-controls={open ? 'basic-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={handleClick1}
                                                >
                                                    <Tooltip title={filtertooltip}><FilterAltIcon /></Tooltip>
                                                </IconButton>
                                                <Menu
                                                    id="basic-menu"
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose1}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'basic-button',
                                                    }}
                                                    className='filter-options'
                                                >
                                                    <div className='filter-menu'>
                                                        <Typography variant='h6'>Filter Public Tickets</Typography>
                                                        <CloseIcon onClick={handleClose1} />
                                                    </div>
                                                    <div className='filter-status'>
                                                        <div className="filter_dropdown filter-tickets">
                                                            <TextField
                                                                label="input"
                                                                value={input}
                                                                onChange={(e) => setinput(e.target.value)}
                                                                placeholder='Please enter User Name and Mobile Number'
                                                            />
                                                        </div>
                                                        <div className='filter-active'>
                                                            <FormControl>
                                                                <FormLabel id="demo-radio-buttons-group-label">Status</FormLabel>
                                                                <RadioGroup
                                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                                    defaultValue="female"
                                                                    name="radio-buttons-group"
                                                                    value={statuspublic}
                                                                    onChange={(e) => setstatuspublic(e.target.value)}
                                                                >
                                                                    <div>
                                                                        <FormControlLabel value="Inprogress" control={<Radio />} label="Inprogress" className="InProgress" />
                                                                        <FormControlLabel value="Created" control={<Radio />} label="Created" className='Pending' />
                                                                    </div>
                                                                    <div>
                                                                        <FormControlLabel value="Completed" control={<Radio />} label="Completed" className='active1' />
                                                                        <FormControlLabel value="Rejected" control={<Radio />} label="Rejected" className='inactive1' />
                                                                    </div>
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    <div className='apply_fliters filter-btn'>
                                                        <Button className='reset-filter' onClick={() => {
                                                            setinput("")
                                                            setstatuspublic("")
                                                            setPagepublic(1)
                                                            fetcpublichtickectsReset();
                                                        }}>Reset Fliter</Button>
                                                        <Button onClick={() => onsubmitpublic()}>Apply Fliter</Button>
                                                    </div>
                                                </Menu>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='employee-table'>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            <div className='employee-index'>
                                                                S.NO
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className='employee-name' onClick={() => handleSortpublic('ticket_no')}>
                                                                Ticket No
                                                                <NorthIcon />
                                                                <SouthIcon />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className='employee-name' onClick={() => handleSortpublic('createdAt')}>
                                                                Created Date
                                                                <NorthIcon />
                                                                <SouthIcon />
                                                            </div>
                                                        </TableCell>
                                                        {/* <TableCell>
                              <div className='employee-name' onClick={() => handleSortpublic('description')}>
                                Description
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell> */}
                                                        <TableCell>
                                                            <div className='employee-name' onClick={() => handleSortpublic('authourId', 'username')}>
                                                                User Name
                                                                <NorthIcon />
                                                                <SouthIcon />
                                                            </div>
                                                        </TableCell>
                                                        {/* <TableCell>
                        <div className='employee-name' onClick={() => handleSortpublic('issueDepartment')}>
                          Department
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell> */}
                                                        <TableCell>
                                                            <div className='employee-name' onClick={() => handleSortpublic('mobileNo')}>
                                                                User Mobile Number
                                                                <NorthIcon />
                                                                <SouthIcon />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className='employee-name' onClick={() => handleSortpublic('status')}>
                                                                Ticket Status
                                                                <NorthIcon />
                                                                <SouthIcon />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className='employee-index'>
                                                            Action
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {tickets.length > 0 ? (
                                                        tickets.map((ticket, index) => (
                                                            <TableRow key={ticket._id}>
                                                                <TableCell align="left"> {startIndexpublic + index + 1}</TableCell>
                                                                <TableCell align="left"> {ticket?.ticket_no ? ticket?.ticket_no : "-----"}</TableCell>
                                                                <TableCell align="left">{new Date(ticket.createdAt).toLocaleString()}</TableCell>
                                                                {/* <TableCell align="left">
                                  {ticket?.managerDescription?.substring(0, 30)}
                                  {ticket?.managerDescription?.length > 30 && '...'}
                                </TableCell> */}
                                                                <TableCell align="left">{ticket?.authourId?.username}</TableCell>
                                                                {/* <TableCell align="left">{ticket?.issueDepartmentId?.name}</TableCell> */}
                                                                <TableCell align="left">{ticket?.authourId?.countrycode} {ticket?.authourId?.mobileNo}</TableCell>
                                                                <TableCell align="left">
                                                                    <span style={{
                                                                        background: ticket.status === 'Created' ? '#FFF7F0' : ticket.status === 'Completed' ? '#E5FCF0' : ticket.status === 'Inprogress' ? '#EDF5FF' : ticket.status === 'Rejected' ? 'rgb(255, 232, 232)' : 'inherit',
                                                                        color: ticket.status === 'Created' ? '#F2994A' : ticket.status === 'Completed' ? '#00A651' : ticket.status === 'Inprogress' ? '#2F80ED' : ticket.status === 'Rejected' ? '#EB5757' : 'inherit',
                                                                    }}>
                                                                        {ticket.status}
                                                                    </span>
                                                                </TableCell>
                                                                <TableCell align="left" className='tickects-lasttablecell'>
                                                                    <Tooltip title={viewpublicticketstooltip}><div className='view-btn' onClick={() => handleTicketSelection(ticket, false)}><VisibilityIcon /></div></Tooltip>
                                                                    {ticket?.status !== "Completed" && (
                                                                        <div className='edit-btn' onClick={() => { handleopenDialog(ticket, false) }}>
                                                                            <Tooltip title={updateticketstatus}>
                                                                                <img src={edit} alt="" />
                                                                            </Tooltip>
                                                                        </div>
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell colSpan={6} align="center">
                                                                <Typography>No Data Found</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                            </section>
                        )}
                    </React.Fragment>
                )}
            </section>
        </React.Fragment >
    );
}

export default Tickets;
